import React from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { FilterPanel } from "./FilterPanel";

export const AnalyticsTable = ({
  customers,
  initParams,
  lazyParams,
  setLazyParams,
  loading,
  footerPage,
  verReporte,
}) => {
  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortField: event.sortField,
        direction: event.sortOrder,
      };
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => verReporte(rowData)}
        />
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="my-2">
        <div className="fontToolClient">Listado de reportes</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return <></>;
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          alignHeader="left"
          field="name"
          showFilterMenu={false}
          sortable
          header="Nombre"
        />
        <Column
          alignHeader="left"
          field="description"
          showFilterMenu={false}
          sortable
          header="Descripción"
        />

        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
