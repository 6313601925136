import React from "react";
import { Button, Toolbar } from "primereact";

export const AnalyticsPanelContain = ({ analytic, closePanel }) => {
  const leftToolbarTemplate = () => {
    return <div className="panel-title">{analytic.name}</div>;
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-times"
            label="Cerrar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <iframe
        src={analytic.frame}
        width="100%"
        height="750"
        className="iframeBorder"
        key={analytic.id}
        title={analytic.name}
      ></iframe>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}
    </div>
  );
};
