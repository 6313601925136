import ConfigEnviroment from "../../config/ConfigEnviroment";
import axios from "axios";

const TOKEN = localStorage.getItem("token");
const conn = axios.create({
  baseURL: ConfigEnviroment.getGatewayUrl(),
  headers: {
    "Content-Type": "application/json",
  },
});

conn.defaults.headers.common["authorization"] = `Bearer ` + TOKEN;
export default conn;
