import React, { useEffect, useRef, useState } from "react";
import { Paginator, Toast } from "primereact";
import { AnalyticsTable } from "./AnalyticsTable";
import { AnalyticsPanelContain } from "./AnalyticsPanelContain";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { Panel } from "./_slidePanel/Panel";
import Container from "react-bootstrap/Container";
import { useFetchAnalytics } from "../hooks/useAnalytics";

const AnalyticsList = () => {
  const { vnAnalytics: viewName, vfVerAnalytics: viewFunction } = viewTypes;
  const showViewFunc = ShowView(viewFunction, viewName);
  const userId = localStorage.getItem("user");

  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    name: "",
    description: "",
    userIds: userId,
  };

  /** */
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [lazyParams, setLazyParams] = useState(initParams);
  const [analytic, setAnalytic] = useState(null);

  const buildFilterDirection = (direction) =>
    direction === 1 ? "ASC" : "DESC";

  const buildFilterAnalytics = (params) => ({
    name: params.name,
    description: params.description,
    userIds: params.userIds,
    size: params.rows,
    page: params.page,
    field: params.sortField,
    direction: params.direction ? buildFilterDirection(params.direction) : null,
  });

  const [filterAnalytics, setFilterAnalytics] = useState(
    buildFilterAnalytics(initParams)
  );

  useEffect(() => {
    setFilterAnalytics(buildFilterAnalytics(lazyParams));
  }, [lazyParams]);

  const fetchAnalytics = useFetchAnalytics(filterAnalytics);

  useEffect(() => {
    if (fetchAnalytics.data) {
      const parsedData = fetchAnalytics.data.reports;

      setTotalRecords(fetchAnalytics.data.pagination.count);
      setCustomers(parsedData);
      setLoading(false);
    }
  }, [fetchAnalytics.data]);

  useEffect(() => {
    setLoading(fetchAnalytics.isLoading);
  }, [fetchAnalytics.isLoading]);

  /** */
  const toast = useRef(null);

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const verReporte = (analytic) => {
    setAnalytic({ ...analytic });
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  return (
    <Container className="evi-campaign-report">
      {showViewFunc && (
        <div className="card">
          <Toast ref={toast} />

          <AnalyticsTable
            customers={customers}
            initParams={initParams}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            verReporte={verReporte}
          />
          {analytic && (
            <Panel isOpen={isOpen} closePanel={closePanel}>
              <AnalyticsPanelContain
                analytic={analytic}
                closePanel={closePanel}
              />
            </Panel>
          )}
        </div>
      )}
    </Container>
  );
};

export default AnalyticsList;
